@import "variables";

// Create your own map
$custom-colors: (
  "lavender":     $lavender,
  "purple":       $purple,
  "light-blue":   $light-blue,
  "tinted-white": $tinted-white,
  "cream":        $cream,
  "orange":       $orange,
);

// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);

$grid-breakpoints: (
  xs: 0,
  sm: $screen-sm-min,
  md: $screen-md-min,
  lg: $screen-lg-min,
  xl: $screen-xl-min,
  xxl: $screen-xxl-min,
  xxxl: $screen-xxxl-min,
);

$border-radius:               0;
$border-radius-sm:            0;
$border-radius-lg:            0;
$border-radius-xl:            0;
$border-radius-2xl:           0;
$border-radius-pill:          0;
$nav-tabs-border-radius:            0;
$nav-pills-border-radius:           0;
$btn-border-radius:                 0;
$btn-border-radius-sm:              0;
$btn-border-radius-lg:              0;
$input-border-radius:               0;
$input-border-radius-sm:            0;
$input-border-radius-lg:            0;


$toast-border-radius:               0;

$dropdown-border-radius: 1px;
$card-border-radius: 0;

$kendo-button-border-width: 0;
$kendo-input-border-width: 1px;

$body-secondary-color: lighten($body-color, 50%);
