
@mixin scrollbar{
    &::-webkit-scrollbar {
        width: 8px !important;
    }

    &::-webkit-scrollbar-track {
        background-color:#202020 !important;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #414141 !important;
        outline: transparent !important;
    }
}

@mixin text-link($color:#ffffff) {
    color: $color !important;
    text-decoration: none !important;
    transition: all 0.5s linear !important;
    font-weight:normal !important;
    &:hover, 
    &:focus{
        color:$color !important;
    }
}

@mixin text-link-underline($color:#ffffff){
    @include text-link($color);
    background: linear-gradient(to right, transparent, transparent),
    linear-gradient(to right, $color, $color, $color) !important;
    background-size: 100% 0.1em, 0 0.1em !important;
    background-position: 100% 100%, 0 100% !important;
    background-repeat: no-repeat !important;
    &:hover, 
    &:focus{
        background-size: 0 0.1em, 100% 0.1em !important;
    }
}

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%)) {
    // For Google Chrome
    &::-webkit-scrollbar {
      width:  $size;
      height: $size;
    }
  
    &::-webkit-scrollbar-thumb {
      background: $foreground-color;
    }
  
    &::-webkit-scrollbar-track {
      background: $background-color;
    }
  
    // For Internet Explorer
    & {
      scrollbar-face-color: $foreground-color;
      scrollbar-track-color: $background-color;
    }
  }