$fa-font-path: "/assets/fonts/fontawesome";
$base-theme: Bootstrap;
$skin-name: WingmanOperations;
$FontAwesomeFontFamily: "Font Awesome 6 Pro";

$lavender: rgba(91,66,162,1);
$purple: rgba(42,9,69,1);
$light-blue: rgba(0,174,217,1);
$tinted-white: rgba(246,229,242,1);
$cream: rgba(255,201,132,1);
$orange: rgba(255,129,19,1);
$white: rgba(255,255,255,1);
$primary: $lavender;
$secondary: $light-blue;
$bs-bg-dark: rgb(33, 37, 41);

/*** BREAKPOINTS ****************************/
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.
$screen-sm-min: 480px;
$screen-md-min: 992px;
$screen-lg-min: 1280px;
$screen-xl-min: 1600px;
$screen-xxl-min: 1920px;
$screen-xxxl-min: 2560px;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);
$screen-lg-max: ($screen-xl-min - 1);
$screen-xl-max: ($screen-xxl-min - 1);
$screen-xxl-max: ($screen-xxxl-min - 1);
$screen-xxxl-max: $screen-xxxl-min;

$header-height: 68px;
$sidebar-width: 260px;
$footerHeight: 66px;
