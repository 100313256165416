//Importing my own custom styles files
@import "variables";
@import "font-awesome";

@import "hamburger-settings";
@import "hamburgers/_sass/hamburgers/hamburgers";

// Bootstrap
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

//Importing styles from NPM packages
@import "_bootstrap_overrides";
@import "@progress/kendo-theme-bootstrap/dist/all.scss";

// Bootstrap
@import "bootstrap/scss/bootstrap";

@import "mixins";

::selection {
  background-color: $secondary;
  color: #fff;
}

.k-datepicker {
  .k-input-button {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 100%;
    cursor: pointer;
  }
}

.k-dropdownlist {
  .k-button {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

kendo-dateinput {
  .k-input-inner {
    margin-right: 30px;
  }
}

.k-maskedtextbox {
  padding: 0;
}

.kendo-switch-wrapper {
  cursor: pointer;
}

.k-grid {
  .printer-checkbox-item{
    text-align:center;
  }
  td {
    button,
    a.btn {
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .k-col-actions {
    text-align: right;

    .btn {
      height: 42px;
      width: 42px;
      padding: 8px;
    }
  }

  &-toolbar {
    .col-auto .btn,
    .text-end .btn:not(:last-child) {
      margin-right: 8px;
    }
  }
}

kendo-buttongroup {
  button:not(:last-child) {
    margin-right: 8px;
  }
}

// Site Styles
.required-asterisk {
  color: red;
  font-weight: bold;
  margin-left: 3px;
  font-size: 1.2em;
}

.default-action-button {
  .button-label {
    margin-right: 0.25rem;
    display: none;
  }
}

.btn-inline {
  width: min-content !important;
  display: inline !important;
}

.is-blurred {
  filter: blur(2px);
}

a.link {
  @include text-link-underline($primary);
}

table td {
  height: 1px;
}
@media only screen and (min-width: 1200px) {
  .default-action-button {
    .button-label {
      display: inline;
    }
  }
}

#inventory-item-fill-grid tr.green {
  background-color: #D2F4EA;
}

#inventory-item-fill-grid tr.pink {   
  background-color: pink;
}

.k-loading-mask {
  //background-color: rgba(0, 0, 0, 0.2);
}

.k-loading-image {
  color:$orange;
}

.k-loading-color {
  background-color:#ffffff;
  opacity:0.4;
}